<template>
  <div>
    <nav>
      <transition
        enter-active-class="transition absolute z-1 duration-250 ease-out"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition absolute z-1 duration-250 ease-out"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div v-if="activeMenu" class="flex w-full flex-col bg-white">
          <div
            class="container sticky top-0 flex items-center border-b border-gray-100 bg-white py-4.5 text-base uppercase leading-none max-md:max-w-full max-md:px-3"
          >
            <UiButton
              variant="clear"
              variant-type="icon"
              size="xs"
              :aria-label="$t('close')"
              @click="setActiveMenu(null)"
            >
              <UiIcon name="chevron_left" class="size-6" />
            </UiButton>
            <component
              :is="getComponentType(activeMenu)"
              :id="
                activeMenu.url.includes('btn-id-')
                  ? extractButtonId(activeMenu.url)
                  : null
              "
              :to="
                !activeMenu.is_title && !activeMenu.url.includes('btn-id-')
                  ? localePath({
                      ...$router.resolve(activeMenu.url),
                      query: {
                        ...$router.resolve(activeMenu.url).query,
                      },
                    })
                  : null
              "
              class="mx-auto inline-flex"
              :class="[
                !activeMenu.is_title &&
                  'cursor-pointer underline-offset-4 hover:underline',
              ]"
              @click="emitRedirectEvent(activeMenu.is_title)"
              :data-e2e-item="!activeMenu.is_title ? 'navigation' : null"
            >
              {{ activeMenu?.currentTranslation?.title }}
            </component>
          </div>
          <div class="container space-y-7.5 py-6 max-md:max-w-full">
            <div v-for="link in activeMenu?.children" :key="link.id">
              <component
                :is="getComponentType(link)"
                :id="
                  link.url.includes('btn-id-')
                    ? extractButtonId(link.url)
                    : null
                "
                :to="
                  !link.is_title && !link.url.includes('btn-id-')
                    ? localePath({
                        ...$router.resolve(link.url),
                        query: {
                          ...$router.resolve(link.url).query,
                        },
                      })
                    : null
                "
                class="mb-5 inline-flex text-xs font-semibold uppercase text-aquamarine-900"
                :class="[
                  !link.is_title &&
                    'cursor-pointer underline-offset-4 hover:underline',
                ]"
                @click="emitRedirectEvent(link.is_title)"
                :data-e2e-item="!link.is_title ? 'navigation' : null"
              >
                <span v-html="link?.currentTranslation?.title" />
              </component>
              <ul class="space-y-3.5 px-5">
                <li
                  v-for="link2 in link.children"
                  :key="link2.id"
                  class="text-sm"
                >
                  <component
                    :is="getComponentType(link2)"
                    :id="
                      link2.url.includes('btn-id-')
                        ? extractButtonId(link2.url)
                        : null
                    "
                    :to="
                      !link2.is_title && !link2.url.includes('btn-id-')
                        ? localePath({
                            ...$router.resolve(link2.url),
                            query: {
                              ...$router.resolve(link2.url).query,
                            },
                          })
                        : null
                    "
                    class="inline-flex items-center gap-1.5"
                    :class="[
                      !link2.is_title &&
                        'cursor-pointer underline-offset-4 hover:underline',
                      Number(activeLink?.id) === link2.id
                        ? 'before:mr-1 before:inline-block before:size-2 before:rounded-full before:bg-aquamarine-900'
                        : '',
                    ]"
                    :data-e2e-item="!link2.is_title ? 'navigation' : null"
                    @click="emitRedirectEvent(link2.is_title)"
                  >
                    <img
                      v-if="link2.image || link2.icon"
                      class="h-4 w-4 object-contain"
                      :src="link2.image || link2.icon"
                      :alt="link2?.currentTranslation?.title"
                    />
                    <span v-html="link2?.currentTranslation?.title" />
                  </component>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>
      <transition
        enter-active-class="transition absolute duration-200 delay-10 ease-out"
        enter-from-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition absolute duration-200 delay-10 ease-out"
        leave-from-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div v-if="!activeMenu" class="container w-full max-md:max-w-full">
          <ul class="mb-1.5 space-y-2.5 py-2.5">
            <li
              v-for="linkParent in navigation"
              :key="linkParent.id"
              class="w-full"
            >
              <component
                :is="getComponentType(linkParent)"
                :id="
                  linkParent.url.includes('btn-id-')
                    ? extractButtonId(linkParent.url)
                    : null
                "
                class="flex items-center justify-between py-2.5 text-base text-neutral-black-600 uppercase leading-none"
                :to="
                  linkParent?.children?.length
                    ? null
                    : localePath(linkParent.url)
                "
                :data-e2e-item="!linkParent.is_title ? 'navigation' : null"
                @click="rootItemClick(linkParent)"
              >
                {{ linkParent?.currentTranslation?.title }}
                <UiIcon name="chevron_right" class="size-6" />
              </component>
            </li>
          </ul>

          <ul
            class="border-t border-neutral-black-600 py-2.5 space-y-2.5 text-sm font-normal uppercase -mx-4 px-4"
          >
            <li class="py-2">
              <NuxtLink
                v-if="!generalStore.isAuthenticated"
                class="flex items-center gap-2.5"
                :to="localePath('/sign-in')"
                aria-label="login"
                data-e2e-cta="profile-icon"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="user" class="size-6" />
                <span>{{ $t("login") }}</span>
              </NuxtLink>
              <NuxtLink
                v-else
                class="flex items-center gap-2.5"
                :to="localePath('/profile')"
                aria-label="login"
                data-e2e-cta="profile-icon"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="user" class="size-6" />
                <span class="text-sm font-normal uppercase">
                  {{ $t("myAccount") }}
                </span>
              </NuxtLink>
            </li>
            <li class="py-2">
              <NuxtLink
                class="flex items-center gap-2.5"
                aria-label="wishlist"
                :to="localePath('/favorites')"
                data-e2e-cta="wishlist"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="heart-rounded" class="w-6" />
                <span> {{ $t("wishlist") }}</span>
              </NuxtLink>
            </li>
            <li class="py-2">
              <NuxtLink
                class="flex items-center gap-2.5"
                aria-label="cart"
                :to="localePath('/cart')"
                data-e2e-cta="cart"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="bag" class="w-6" />
                <span> {{ $t("myCart") }}</span>
              </NuxtLink>
            </li>
            <li class="py-2">
              <NuxtLink
                class="flex items-center gap-2.5"
                :aria-label="$t('bookAnAppointment')"
                :to="localePath('/book-an-appointment')"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="calendar" class="w-6" />
                <span> {{ $t("bookAnAppointment") }}</span>
              </NuxtLink>
            </li>
            <li class="py-2">
              <NuxtLink
                class="flex items-center gap-2.5"
                :aria-label="$t('contactUs')"
                :to="localePath('/contact')"
                @click="emitRedirectEvent()"
              >
                <UiIcon name="message" class="w-6" />
                <span> {{ $t("contactUs") }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </transition>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { NavMenuItem } from "ecom-types";
import { NuxtLink } from "#components";

const { headerMenu } = useGeneralStore();

const localePath = useLocalePathPolyfill();
const route = useRoute();
const generalStore = useGeneralStore();

const { findBreadcrumb } = useBreadcrumbs();

const activeMenu = ref<TreeItem<NavMenuItem> | null>(null);

const navigation = computed(() => {
  return toTree(headerMenu);
});

const breadcrumbs = findBreadcrumb(navigation.value, route);

const activeLink = computed(() => {
  return breadcrumbs[breadcrumbs.length - 1];
});

navigation.value.forEach((item) => {
  if (breadcrumbs.length) {
    if (item.id === breadcrumbs[0].id && item.children?.length) {
      activeMenu.value = item;
    }
  }
});

const emit = defineEmits(["eventRedirect"]);

watch(
  () => route.fullPath,
  () => {
    setActiveMenu(null);
  },
);

function setActiveMenu(item: TreeItem<NavMenuItem> | null) {
  if (item?.id === activeMenu.value?.id) {
    activeMenu.value = null;
    return;
  }
  activeMenu.value = item;
  isLoginItemOpen.value = false;
}
function emitRedirectEvent(ignoreEmit?: boolean) {
  if (ignoreEmit) return;

  emit("eventRedirect");
}
const isLoginItemOpen = ref(false);

function getComponentType(link: NavMenuItem) {
  if (link.is_title) {
    return "div";
  } else if (link.url.includes("btn-id-")) {
    return "button";
  } else {
    return NuxtLink;
  }
}

function rootItemClick(item: TreeItem<NavMenuItem> | null) {
  if (item && !item?.children?.length) {
    emitRedirectEvent();
  } else {
    setActiveMenu(item);
  }
}
</script>
